// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-spotlight-js": () => import("./../src/templates/spotlight.js" /* webpackChunkName: "component---src-templates-spotlight-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-role-js": () => import("./../src/templates/role.js" /* webpackChunkName: "component---src-templates-role-js" */),
  "component---src-templates-system-component-js": () => import("./../src/templates/systemComponent.js" /* webpackChunkName: "component---src-templates-system-component-js" */),
  "component---src-templates-specification-document-walkthrough-js": () => import("./../src/templates/specificationDocumentWalkthrough.js" /* webpackChunkName: "component---src-templates-specification-document-walkthrough-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-spotlight-js": () => import("./../src/pages/spotlight.js" /* webpackChunkName: "component---src-pages-spotlight-js" */)
}

